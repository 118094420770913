@import "shared/styles/media.scss";

.review_form {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  padding: 16px;
  overflow: hidden;

  font-family: var(--font);
  min-height: 100%;
  max-height: calc(100vh - 100px);
  background: #fff;
  border-radius: 20px;
  overflow: auto;
  padding-bottom: 100px;

  // scrollbar none
  &::-webkit-scrollbar {
    display: none;
  }

  @include md {
    max-width: none;
    width: 100%;
    border-radius: 0;
    height: 100%;
  }

  &.success {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding-bottom: 16px;

    @include md {
      max-width: none;
    }
  }

  &.success_with_orders {
    max-width: 430px;
    padding: 20px;
    border-radius: 20px;
    background: hsla(0, 0%, 95%, 1);

    @include md {
      max-width: none;
      width: 100%;
      border-radius: 0;
      padding: 0;
    }
  }
}

.head {
  position: relative;
  padding-top: 10px;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: hsla(0, 0%, 32%, 1);

    @include md {
      top: 5px;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  }
}

.product {
  margin: 15px 0;
}

.stars_container {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: height 0.3s ease-out;

  &.accent {
    height: 200px;
  }
}

.comments_container {
  transition: opacity 0.2s ease-out;

  &.hidden {
    opacity: 0;
  }

  .comments_container_title {
    margin-top: 25px;
    font-weight: 600;
    font-size: 18px;
  }

  .comments_container_description {
    color: var(--UIMain-Black, #000);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.photo {
  margin-top: 30px;
}

.photo_row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.actions {
  width: 100%;
  box-shadow: 0 -4px 20px 0 rgba(117, 115, 111, 0.2);

  padding: 6px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9999;

  background: white;

  .button {
    height: 52px !important;
    font-size: 17px !important;
    width: 100% !important;

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    padding: 0 16px !important;

    border: none !important;
    outline: none !important;

    border-radius: 16px !important;
    position: relative !important;

    font-weight: 600 !important;

    letter-spacing: -0.02em !important;

    background: var(--primary) !important;
    color: white !important;
  }

  @media (min-width: 768px) {
    position: absolute;
    background: unset;
  }
}

.success_container {
  font-family: var(--font);
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;

  .image {
    opacity: 0.9;
  }

  .title {
    margin-top: 20px;
    color: var(--Black, #000);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .description {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
  }

  .success_actions {
    margin-top: 40px;

    .button {
      width: 150px;
      border-radius: 15px !important;
      height: 44px !important;
      font-size: 17px !important;

      background: var(--primary) !important;
      color: white !important;
      border: none !important;
    }
  }
}

.loader_block {
  position: absolute;
  inset: 0;
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;

  .loader {
    width: 16px;
    height: 16px;
    border: 1px solid black;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.success_with_orders {
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include md {
    width: 100%;
  }

  .head {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @include md {
      padding: 20px 20px 0;
    }

    .title_row {
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;

      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: left;
      }

      .icon {
        width: 25px;
        height: 25px;
      }
    }

    .description {
      color: var(--UIMain-Black, #000);

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }
  }

  .content {
    display: flex;
    width: 100%;
    padding: 20px 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background: #fff;
    border-radius: 20px;

    .title {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 21.6px */
    }

    .orders {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;

      .order {
        position: relative;
        padding: 0;

        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;

        @include md {
          gap: 15px;
        }

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          border-top: 1px solid hsla(0, 0%, 88%, 1);
          position: absolute;
          top: -20px;
          user-select: none;
          pointer-events: none;
        }

        &:first-child {
          &::before {
            display: none;
          }
        }

        .image_wrapper {
          .image {
            position: relative;
            flex-shrink: 0;

            border-radius: 20px;
            overflow: hidden;

            display: flex;
            align-items: center;
            justify-content: center;

            .image_inner {
              position: relative;
              width: 128px;
              padding-bottom: 64%;

              @include md {
                width: 110px;
              }

              img {
                object-fit: contain;
              }
            }
          }
        }

        .order_content {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 100%;

          .order_content_header {
            display: flex;
            flex-direction: row;
            gap: 5px;
            justify-content: space-between;
            align-items: center;

            .title {
              color: #000;

              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
            }

            .points_text {
              font-weight: 700;
              text-align: right;
            }
          }

          .review_input {
            .review_input_star {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.points_text {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */

  background: var(--point-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.review_page {
  @media (min-width: 768px) {
    max-height: none;

    .actions {
      position: static;
      border-radius: 0;
      box-shadow: none;
      margin-top: 20px;
    }
  }
}
