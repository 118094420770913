@import "shared/styles/media.scss";

.review_modal {
  position: fixed;
  inset: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 40px;

  &::-webkit-scrollbar {
    display: none;
  }

  @include md {
    padding: 0;
  }

  .review_modal_overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  .review_modal_inner {
    position: relative;
    z-index: 9;
    display: flex;
    max-width: 450px;
    border-radius: 20px;
    height: auto;

    animation: appear_content 0.3s ease-in-out;

    @include md {
      max-width: none;
      max-height: none;
      width: 100%;
      height: 100%;
    }
  }

  @keyframes appear_content {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
