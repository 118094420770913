$sm: 576;
$md: 768;
$lg: 992;
$xl: 1280;
$xxl: 1400;

@mixin sm {
  @media (max-width: #{$sm}px) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$md}px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$lg}px) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$xl}px) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: #{$xxl}px) {
    @content;
  }
}
