.close_confirm_modal {
  font-family: var(--font);
}

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.description {
  margin-top: 10px;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.actions {
  margin-top: 20px;

  display: flex;
  gap: 5px;
}

.button {
  flex: 1;

  border: unset;
  outline: unset;

  padding: 15px !important;
  border-radius: 15px !important;

  font-family: var(--font);
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 140% !important;

  background: var(--UIMain-Gray-Background, #f2f2f2) !important;
  color: black !important;

  &.primary {
    background: var(--primary) !important;
    color: white !important;
    font-weight: 600 !important;
  }
}
