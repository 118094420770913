.payment_methods {
}

.space {
  margin-top: 20px;
}

.item {
  width: 100%;
  display: flex;
  gap: 5px;

  align-items: center;
  padding: 11px 0;

  border-bottom: 1px solid var(--UIMain-Outline, #e0e0e0);

  color: var(--UIMain-Black, #000);
  /* p16-med */
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
}

.logo {
  width: 30px;
  height: 30px;
}

.label {
  min-height: 30px;
  display: flex;
  align-items: center;
  gap: 7px;

  margin-right: auto;

  .sbp_icon {
    width: 53px;
    height: auto;
  }
}

.item_expanded {
  width: 100%;
  display: flex;
  flex-direction: column;

  color: var(--UIMain-Black, #000);
  /* p16-med */
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */

  .body {
    padding-left: 30px;
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.5s;
  }

  &:not(.expanded) {
    .body {
      max-height: 0 !important;
      border-bottom: 1px solid var(--UIMain-Outline, #e0e0e0);
    }
  }

  .radio_movable {
    position: relative;
    top: 0;
    transition: top 0.35s ease;
    transition-delay: 0.15s;
  }

  &.expanded {
    .radio_movable {
      top: 55px;
      transition: top 0.2s ease;
      transition-delay: 0s;
    }
  }
}

.item_head {
  width: 100%;
  display: flex;
  gap: 5px;

  align-items: center;
  padding: 11px 0;

  color: var(--UIMain-Black, #000);
  /* p16-med */
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
}

.others_methods_button {
  display: flex;
  align-items: center;
  padding: 15px 0;
  color: var(--UIMain-Black, #000);
  /* p16-med */
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
}

.others_methods_description {
  margin-top: 30px;

  color: var(--UIMain-Black, #000);

  /* p16-reg */
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.radio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid var(--UIMain-Outline, #e0e0e0);

  &.active {
    background: var(--accent);
    border-color: var(--accent);
    color: white;
  }
}
